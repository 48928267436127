import React from "react";

import css from "../Styles/home.module.scss";
import Hero from "../assets/HeroImage.png";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import Cards from "../Components/Cards";
import Gototop from "../Components/Gototop";
import MouseMove from "../Components/MouseMove";
const Home = () => {
  return (
    <>
      <div>
        <MouseMove />

        <div className={css.home}>
          <div className={css.home_wrapper}>
            <div className={css.home_left}>
              <h6>
                More then Faster{" "}
                <span>
                  <FastfoodIcon />
                </span>
              </h6>
              <h1>
                Be The Faster in Delivering on Your <span>Food</span>
              </h1>
              <p>
                Our Mission is so filling your tummy with delicious food and
                with fast and free delivery
              </p>
              <button>Explore More</button>
            </div>
            <div className={css.home_right}>
              <div className={css.home_contact}>
                <button>Contact Us</button>
              </div>
              <div className={css.home_image}>
                <img src={Hero} alt="" />
              </div>
            </div>
          </div>
        </div>
        <Gototop />
        <Cards />
      </div>
    </>
  );
};

export default Home;
