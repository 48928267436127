import React, { useState, useEffect } from "react";
import FavoriteIcon from "@mui/icons-material/Favorite";

import axios from "axios";
import { auth } from "../firebase";

// css
import css from "../Styles/Card.module.scss";
import { getDatabase, ref, set } from "firebase/database";
const Cards = () => {
  const [items, setItems] = useState([]);
  const userId = auth?.currentUser?.uid;

  useEffect(() => {
    axios
      .get(
        "https://api.spoonacular.com/food/products/search?query=burger&apiKey=4ae40c27f0f14743a76179ae46eeeeb6"
      )
      .then((res) => {
        console.log(res.data.products);
        setItems(res.data.products);
      });
  }, []);

  const handleAdd = (item)=>{
    const category_id = item.id;
    const category_title = item.title;
    const category_image = item.image;
    
    function writeUserData() {
      const db = getDatabase();
      set(ref(db, 'favourite/' + userId), {
       item_id: category_id,
       item_name: category_title,
       item_image: category_image,
      });
    }
    writeUserData()
  }
  return (
    <>
      <div className={css.card}>
        <div className={css.card_wrapper}>
          <div className={css.card_title}>
            <h1>Categrious</h1>
          </div>
          <div className={css.cards}>
            {items.map((item, i) => {
              if (item) {
                return (
                  <div className={css.itemCard} key={i}>
                    <div className={css.itmeCard_img}>
                      <img src={item.image} alt="" srcset="" />
                    </div>
                    <div className={css.itmeCard_des}>
                      <h1>{item.title}</h1>
                     <button onClick={()=>handleAdd(item)}>Add To Favriout</button>
                    </div>

                  </div>
                );
              } else {
                <div className={css.itemCard}>
                  <h1>Loading</h1>
                </div>;
              }
            })}
          </div>
        </div>
      </div>
      <div></div>
    </>
  );
};

export default Cards;
