import React,{useState} from "react";

// Material Styles
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
// Material Icons
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import PersonIcon from '@mui/icons-material/Person';
import { auth } from "../firebase";
import {createUserWithEmailAndPassword} from "firebase/auth"
const Signup = ({ signupModal, setSignupModal, setRouteStatus }) => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState(false)
  const handleSubmit = ()=>{
    createUserWithEmailAndPassword(auth, email, password,name )
    .then((userCredential) => {
      const user = userCredential.user;
      const token = user.UserImpl.accessToken
        localStorage.setItem("name",name)
        localStorage.setItem("accessToken",token)
        console.log(user.UserImpl.accessToken);
      })
      .catch((error) => {
        setError(true);
      });
  }
  const data = localStorage.getItem("name")
  console.log(data)
  const handleClose = () => setSignupModal(false);
  return (
    <Dialog open={signupModal} className="signupModal" onClose={handleClose}>
      <DialogTitle className="modal_title">
        <h1>Signup</h1>
      </DialogTitle>
      <DialogContent className="modal_contact">
    
        <div className="input">
          <div className="input_logo">
            <PersonIcon />
          </div>
          <div className="input_text">
            <div className="input_title">Username </div>
            <div className="input_field">
              <input type="text" name="name" onChange={(e)=>setName(e.target.value)}  />
            </div>
          </div>
        </div>
        <div className="input">
          <div className="input_logo">
            <MailOutlineIcon />
          </div>
          <div className="input_text">
            <div className="input_title">Email </div>
            <div className="input_field">
              <input type="text" name="email" onChange={(e)=>setEmail(e.target.value)} />
            </div>
          </div>
        </div>
        <div className="input">
          <div className="input_logo">
            <LockOpenIcon />
          </div>
          <div className="input_text">
            <div className="input_title">Password </div>
            <div className="input_field">
              <input type="password" name="password" onChange={(e)=>setPassword(e.target.value)} />
            </div>
          </div>
        </div>

        <div className="modal_changer">
          <p>
            {" "}
            If you dont have Accout <span onClick={()=>setRouteStatus(true)}>Login</span>
          </p>
        </div>

        <div className="modal_btn">
          <button onClick={()=>handleSubmit()}>SignUp</button>
        </div>
      </DialogContent>

      <DialogActions></DialogActions>
    </Dialog>
  );
};

export default Signup;
