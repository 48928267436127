import React,{useEffect,useState} from 'react'
import { getDatabase, ref, onValue} from "firebase/database";
import { auth } from "../firebase";
import css from "../Styles/Card.module.scss";


const Favourite = () => {
  const [items, setItems] = useState([]);
  const userId = auth?.currentUser?.uid;

  useEffect(()=>{
    const user_Id = auth?.currentUser?.uid;
     const db =getDatabase()
    const query = ref(db, "favourite/" + user_Id);
    return onValue(query, (snapshot) => {
      const data = snapshot.val();
      console.log(data);
      setItems([data])

      if (snapshot.exists()) {
        Object.values(data).map((project) => {
          //   console.log("🚀 ~ file: Home.js:21 ~ Object.values ~ data", data);
        });
      }
    });
  },[])
  return (
     <div className={css.card}>
        <div className={css.card_wrapper}>
          <div className={css.card_title}>
            <h1>Categrious</h1>
          </div>
          <div className={css.cards}>
            {items.map((item, i) => {
              if (item) {
                return (
                  <div className={css.itemCard} key={i}>
                    <div className={css.itmeCard_img}>
                      <img src={item.item_image} alt="" srcset="" />
                    </div>
                    <div className={css.itmeCard_des}>
                      <h1>{item.item_name}</h1>
                    </div>

                  </div>
                );
              } else {
                <div className={css.itemCard}>
                  <h1>Loading</h1>
                </div>;
              }
            })}
          </div>
        </div>
      </div>
  );
}

export default Favourite