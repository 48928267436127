import { initializeApp } from "firebase/app";
import {getAuth} from "firebase/auth"
const firebaseConfig = {
  apiKey: "AIzaSyDeJbZxuWF1_tQTTmBYsGRXVedALrNc1PE",
  authDomain: "codejam-foodapp.firebaseapp.com",
  projectId: "codejam-foodapp",
  storageBucket: "codejam-foodapp.appspot.com",
  messagingSenderId: "859561174795",
  appId: "1:859561174795:web:ba3f90cec4550c6db3240e"
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth()