import React from "react";
import { Link } from "react-router-dom";
// img
import wave from "../assets/wave.png";

// css
import css from "../Styles/footer.module.scss";
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
const Footer = () => {
  return (
    <>
      <div className={css.footer}>
        <div className="waves">
          <div className="wave" id="wave1"></div>
          <div className="wave" id="wave2"></div>
          <div className="wave" id="wave3"></div>
          <div className="wave" id="wave4"></div>
        </div>
        <div className={css.footer_wrapper}>
          <div className={css.footer_item}>
            <div className={css.footer_logo}>
              <span>
                <FacebookIcon size={31} />
              </span>
              <span>
                <TwitterIcon size={31} />
              </span>
              <span>
                <LinkedInIcon size={31} />
              </span>
              <span>
                <InstagramIcon size={31} />
              </span>
            </div>

            <ul>
              <Link to="home" smooth={true}>
                <li>Home</li>
              </Link>
              <Link to="about" smooth={true}>
                <li>About</li>
              </Link>
              <Link to="contact" smooth={true}>
                <li>Contact</li>
              </Link>
            </ul>
          </div>
          <div className={css.footer_copy}>
            <p>@2022 Hasnain Irfan | All Right Reserved</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
