import React, { useEffect, useState } from "react";
// css
import css from "../Styles/navbar.module.scss";
import "../Styles/global.scss";
// React Router Dom
import { NavLink } from "react-router-dom";
import Login from "../Pages/Login";
import Signup from "../Pages/Signup";
import { auth } from "../firebase";
import FavoriteIcon from "@mui/icons-material/Favorite";
import LocalGroceryStoreIcon from "@mui/icons-material/LocalGroceryStore";
import CardModal from "./CardModal";
const Navbar = () => {
  const [navbar, setnavbar] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const [signupModal, setSignupModal] = useState(false);
  const [routeStatus, setRouteStatus] = useState(true);
  const userid = auth?.currentUser?.uid;
  let username = localStorage.getItem("name");

  const [scrollPosition, setScrollPosition] = useState("0%");

  const ScrollValue = () => {
    let pos = document.documentElement.scrollTop;
    let calc =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;

    let scroll = Math.round((pos * 100) / calc);
    setScrollPosition(`${scroll}%`);
  };
  useEffect(() => {
    window.onscroll = ScrollValue;
  }, []);

  // Sticky Navbar Start
  const [stickyNavbar, setStickyNavbar] = useState(false);
  const ChangeNav = () => {
    if (window.scrollY >= 30) {
      setStickyNavbar(true);
    } else {
      setStickyNavbar(false);
    }
  };
  window.addEventListener("scroll", ChangeNav);
  // Sticky Navbar End

  const handleClose = () => {
    setnavbar(false);
  };
  const Routes = [
    {
      link: "/",
      label: "Home",
    },
    {
      link: "/favourite",
      label: "Favourite",
    },
  ];
  return (
    <>
      <div className={`${css.navbar} ${stickyNavbar ? css.navbarSticky : ""} `}>
        <div className={css.navbar_wrapper}>
          <div
            className="navbarLine"
            style={{
              width: scrollPosition,
              transition: "0.1s ",
              background: "#fff",
            }}
          ></div>
          <div className={css.navbar_left}>
            <div className={css.navbar_logo}>
              <h3>Food App</h3>
            </div>
          </div>
          <div
            className={`${css.navbar_center} ${
              navbar ? css.navbar_centerActive : ""
            }`}
          >
            <ul>
              {Routes.map((item, i) => {
                return (
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? css.navbarActive : ""
                    }
                    to={item.link}
                    key={i}
                    onClick={() => setnavbar(false)}
                  >
                    <li>{item.label}</li>
                  </NavLink>
                );
              })}
            </ul>
          </div>
          <div className={css.navbar_right}>
            <div className={css.navbar_action}>
              <div className={css.loginBtn}>
                <button
                  onClick={() =>
                    routeStatus === true
                      ? setLoginModal(true)
                      : setSignupModal(true)
                  }
                >
                  Login/Register
                </button>
              </div>
            </div>
            <h2>{username}</h2>
          </div>
        </div>
      </div>
      {routeStatus == true ? (
        <Login
          loginModal={loginModal}
          setLoginModal={setLoginModal}
          setRouteStatus={setRouteStatus}
        />
      ) : (
        <Signup
          signupModal={signupModal}
          setSignupModal={setSignupModal}
          setRouteStatus={setRouteStatus}
        />
      )}
    </>
  );
};

export default Navbar;
