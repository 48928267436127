import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Footer from "../Components/Footer";
import MouseMove from "../Components/MouseMove";

// Components
import Navbar from "../Components/Navbar";

// Pages
import About from "../Pages/About";
import Favourite from "../Pages/Favourite";
import Home from "../Pages/Home";
import Login from "../Pages/Login";

const Routing = () => {

  return (
    <>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/favourite" element={<Favourite />} />
          <Route path="/*" element={<h1>404 Error</h1>} />
        </Routes>
        <Footer/>
      </BrowserRouter>
    </>
  );
};

export default Routing;
