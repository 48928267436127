import React, { useState } from "react";

// Material Styles
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
// Material Icons
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { Link } from "react-router-dom";
import { auth } from "../firebase";
import {signInWithEmailAndPassword} from "firebase/auth"
const Login = ({ loginModal, setLoginModal,setRouteStatus }) => {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const handleClose = () => setLoginModal(false);
  const handleChageRoute = () => setRouteStatus(false)

  const handleSubmit = ()=>{
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        console.log(user);
      })
      .catch((error) => {
        console.log(error)
      });
  }
  return (
    <Dialog open={loginModal}  className="modal" onClose={handleClose}>
      <DialogTitle className="modal_title">
        <h1>Login</h1>
      </DialogTitle>
      <DialogContent className="modal_contact">
        <div className="input">
          <div className="input_logo">
            <MailOutlineIcon />
          </div>
          <div className="input_text">
            <div className="input_title">Email </div>
            <div className="input_field">
              <input type="text" onChange={(e)=>setEmail(e.target.value)}/>
            </div>
          </div>
        </div>
        <div className="input">
          <div className="input_logo">
            <LockOpenIcon />
          </div>
          <div className="input_text">
            <div className="input_title">Password </div>
            <div className="input_field">
              <input type="password" onChange={(e)=>setPassword(e.target.value)}/>
            </div>
          </div>
        </div>

        <div className="modal_changer">
         <p> If you dont have Accout <span onClick={()=>handleChageRoute()}>Register</span></p>
        </div>
        <div className="modal_btn">
          <button onClick={()=>handleSubmit()}>Login</button>
        </div>
      </DialogContent>

      <DialogActions></DialogActions>
    </Dialog>
  );
};

export default Login;
